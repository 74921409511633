import React from "react";

export default function Stats(){
  return(
    <section style={{paddingTop:'0'}} className="_06ea9536">
    <div className="wr"><div className="_5caa455b">
    <header className="_e8aff04e">
    <h2 className="_bdd08f16">Nga Shifrat</h2><p className="_0a426ab2">Duke nderuar jetë</p></header><div className="_3227deca"><div className="_a10b6630"><div className="_1e23c71d"><span data-value="3421" className="countup">+5000</span></div><span className="_cb9fe13f">Punime te punuara</span></div><div className="_a10b6630"><div className="_1e23c71d"><span data-value="2890" className="countup">32</span></div><span className="_cb9fe13f">Vite ne sherbim</span></div><div className="_a10b6630"><div className="_1e23c71d"><span data-value="75" className="countup">4</span></div><span className="_cb9fe13f">Gdhendës artizanale
    </span></div></div></div></div></section>
  
  )
}