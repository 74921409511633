import { useEffect, useState } from 'react';
import { RatingStars } from '../../../ui/icons';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios'
const reviews = { href: '#', average: 4, totalCount: 117 };

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const location = useLocation();
  const [productid, setProductID] = useState('');
  const [product, setProduct] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [photos, setPhotos] = useState([]);
  const [shareError, setShareError] = useState(null);
  useEffect(() => {
    const pathElements = location.pathname.split('/');
    const lastElement = pathElements[pathElements.length - 1];
    setProductID(lastElement);
  }, [location]);
  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setPhotos((prevPhotos) => {
      const newPhotos = prevPhotos.filter((p) => p !== photo);
      return [photo, ...newPhotos];
    });
  };
  const handleInstagramShare = async () => {
    try {
      // User Access Token obtained from authentication
      const userAccessToken = "EAAPm9SlTL3cBO8ZAmjkyVZB6mRsBw7otrYsHGvmkMEAolu3VsnA093gZBZAZA4wz9DgvsqDhpI8WwqZCL7TaVU8TsMIXBcqMQZAcJ5dhfxZC5KTWkZCDpvIPyBB5ZCjUnBne7xtNNjpsQhhlqpbJZA2WZAZClpa4Azp9GU9yKAQY3ZCdSIc9U2cuZBE3ctzMRKwTBSXvZCOlvOPMimqRlyLOqrkYSOHazOgHNAF4cuaVJqbUcKwoZC4CRUrKK3WyiTLuSfMxM";

      // Prepare content to be shared (e.g., product name, description, and image URL)
      const content = {
        caption: `${product.name}: ${product.description}`,
        media_url: photos,
      };

      // Make a POST request to Instagram's media endpoint to upload the image
      const uploadResponse = await axios.post(
        `https://graph.instagram.com/me/media?access_token=${userAccessToken}`,
        content
      );

      // Check if the upload was successful
      if (uploadResponse.data.id) {
        // If successful, share the uploaded media
        await axios.post(
          `https://graph.instagram.com/me/media_publish?media_id=${uploadResponse.data.id}&access_token=${userAccessToken}`
        );

        // Reset any previous errors
        setShareError(null);
        // Display success message or handle success scenario
        console.log("Product shared successfully on Instagram!");
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      // Handle errors
      console.error("Error sharing product on Instagram:", error.message);
      setShareError(error.message);
    }
  };
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, 'product', productid);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          const productData = { id: productSnap.id, ...productSnap.data() };
          setProduct(productData);
          setPhotos(productData.photos);
          setSelectedPhoto(productData.photos[0]);
        } else {
          console.error('No such product!');
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    if (productid) {
      fetchProduct();
    }
  }, [productid]);

  if (!product) {
    return <div>Loading...</div>;
  }
  console.log(product)

  return (
    <div className="bg-white flex items-center justify-center w-full ">
      
      <section className="py-12 sm:py-16"> 
    {  product != null  && (
    
    <div className="container mx-auto px-4">
    
    <>
    <nav className="flex">
      <ol role="list" className="flex items-center">
        <li className="text-left">
          <div className="-m-1">
            <a href="#" className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"> Home </a>
          </div>
        </li>

        <li className="text-left">
          <div className="flex items-center">
            <span className="mx-2 text-gray-400">/</span>
            <div className="-m-1">
              <a href="#" className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"> Products </a>
            </div>
          </div>
        </li>

        <li className="text-left">
          <div className="flex items-center">
            <span className="mx-2 text-gray-400">/</span>
            <div className="-m-1">
              <a href="#" className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800" aria-current="page"> {product.name} </a>
            </div>
          </div>
        </li>
      </ol>
    </nav>

    <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
      <div className="lg:col-span-3 lg:row-end-1">
        <div className="lg:flex lg:items-start">
          <div className="lg:order-2 lg:ml-5">
            <div className="max-w-xl overflow-hidden rounded-lg">
              <img className="h-full w-full max-w-full object-cover" src={selectedPhoto} alt="" />
            </div>
          </div>

          <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
            <div className="flex flex-row items-start lg:flex-col">
            {photos.map((photo, index) => (
                          <button
                            key={index}
                            type="button"
                            className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                            onClick={() => handlePhotoClick(photo)}
                          >
                            <img className="h-full w-full object-cover" src={photo} alt="" />
                          </button>
                        ))}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
        <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl">{product.name}</h1>

        <div className="mt-5 flex items-center">
          <RatingStars/>
          <p className="ml-2 text-sm font-medium text-gray-500">1,209 Reviews</p>
        </div>

      

        <div className="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
          <div className="flex items-end">
            <h1 className="text-3xl font-bold">ALL {product.price}</h1>
          
          </div>
         
          <button type="button" className="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-gray-900 bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
            <Link to={`/order/${product.id}`}>Porosit Tani</Link> 
          </button>
        </div>

        <ul className="mt-8 space-y-2">
          <li className="flex items-center text-left text-sm font-medium text-gray-600">
            <svg className="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" className="" />
            </svg>
           Porosit kudo neper bote
          </li>

         
        </ul>
      </div>

      <div className="lg:col-span-3">
        <div className="border-b border-gray-300">
          <nav className="flex gap-4">
            <a href="#" title="" className="border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 hover:border-gray-400 hover:text-gray-800"> Pershkrimi </a>

        
          </nav>
        </div>

        <div className="mt-8 flow-root sm:mt-12">
          <h1 className="text-3xl font-bold">Na kontaktoni per te porositur</h1>
        <p className="mt-4">{product.description}.</p>
         
        </div>
      </div>
    </div>
    
    </>
    
   
    
  </div>)} 
</section>
    </div>
  );
}
